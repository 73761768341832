.container {
    background-color: #131925;
    height: 100vh;
    width: 100vw;
    display: flex;
    color: #f2f3f5;
    overflow: hidden;
}

.title{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "Pacifico", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 4rem;
}

.subtitle{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
    font-style: italic;
}


.card {
    scale: 0.75;
    margin: auto;
    position: relative;
    background-color: #1f2a3f;
    width: 300px;
    height: 400px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.6s; /* Ensure transition is applied in both states */
    padding: 20px;  
}

.rule{
    z-index: 2;
    transform: translateZ(600px);
    text-align: center;
}

.tilt {
    margin: auto;
}

.card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-front {
    background-color: #f2f3f5;
    color: black;
  }
  
  .card-back {
    background-color: #2c3e50; /* Solid background to hide front text */
    color: white;
    transform: rotateY(180deg); /* Flip the back content */

  }

.turn-around {
    transform: rotateY(180deg);
}

.hidden{
    display: none;
}
.entrance {
    animation: fadeInDown 2s ease-in-out forwards;
}

.instagram{
    position: absolute;
    bottom: 10px;
    color: #f2f3f5;
    font-size: 1rem;
    opacity: 0.4;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

