/* 
  We've already included normalize.css. 

  But we'd like a modern looking boilerplate. 
  Clean type, sans-serif, and a nice color palette. 
  
*/

/* CSS Reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  user-select: none;
  -webkit-user-select: none; /* For Chrome, Safari, and Opera */
  -moz-user-select: none;    /* For Firefox */
  -ms-user-select: none;     /* For IE/Edge */
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.glare-wrapper{
  border-radius: 10px;
}


*{
  padding: 0px;
  margin: 0px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  padding: 0px;
  margin: 0px;
}


html,
body {
  height: 100%;
  width: 100%;
}

